import groq from 'groq'

import { simpleContentFragment } from './content'
import { imageFragment } from './image'

const productPhotosFragment = groq`
  "main": galleryPhotos[] {
    forOption,
    photos[] {
      ${imageFragment}
    },
  },
  "listing": listingPhotos[] {
    forOption,
    "default": listingPhoto {
      ${imageFragment}
    },
    "hover": listingPhotoHover {
      ${imageFragment}
    },
  },
`

const productVariantFragment = groq`
  sku,
  barcode,
  title,
  price,
  comparePrice,
  inStock,
  lowStock,
  seo,
  options[] {
    name,
    position,
    value,
  },
  "id": variantID,
`

export const productFragment = groq`
  type,
  sku,
  barcode,
  title,
  price,
  comparePrice,
  description[] {
    ${simpleContentFragment}
  },
  inStock,
  lowStock,
  useGallery,
  surfaceOption,
  options[] {
    name,
    position,
    values[],
  },
  optionSettings[] {
    forOption,
    "color": color->color {
      hex,
    },
  },
  optionNames[] {
    forOption,
    name,
  },
  "createdAt": _createdAt,
  "slug": slug.current,
  "id": productID,
  "photos": {
    ${productPhotosFragment}
  },
  "variants": *[
    _type == "productVariant" &&
    parentId == ^._id &&
    !wasDeleted &&
    !isDraft
  ] {
    ${productVariantFragment}
  },
  "filters": filters[] {
    "slug": filter->slug.current,
    forOption,
  },
`

export const reviewProductFragment = groq`
  "id": productID,
  "slug": slug.current,
  sku,
  title,
  "photos": {
    ${productPhotosFragment}
  },
`

const minimalContentProductFragment = groq`
  _id,
  locale,
  description[] {
    ${simpleContentFragment}
  },
  modules[],
`

export const productsByShopifyIdQuery = groq`
  *[
    _type == "product" &&
    productID == $shopifyProductId
  ] {
    ${minimalContentProductFragment}
  }
`

const variantQueryFragment = groq`
  "product": *[
    _type == "product" &&
    _id == ^.parentId
  ][0] {
    "id": productID,
    title,
    "slug": slug.current,
  },
  "id": variantID,
  sku,
  title,
  price,
  comparePrice,
  sellingPlanId,
  "photos": {
    "cart": *[
      _type == "product" &&
      _id == ^.parentId
    ][0].cartPhotos[] {
      forOption,
      "default": cartPhoto {
        ${imageFragment}
      },
    },
  },
  options[] {
    name,
    position,
    value,
  },
`

export const productVariantQuery = groq`
  *[
    _type == "productVariant" &&
    variantID == $shopifyProductVariantId &&
    locale == $locale
  ][0] {
    ${variantQueryFragment}
  }
`

export const productVariantsQuery = groq`
  *[
    _type == "productVariant" &&
    variantID in $shopifyProductVariantIds &&
    locale == $locale
  ] {
    ${variantQueryFragment}
  }
`

export const productVariantsByShopifyIdQuery = groq`
  *[
    _type == "productVariant" &&
    productID == $shopifyProductId
  ] {
    _id,
    variantID,
  }
`

const catalogueProductFragment = groq`
  type,
  title,
  "id": productID,
  "slug": slug.current,
  "subscriptionProductIds": subscriptionProducts[]->productID,
`

export const productCatalogueQuery = groq`
  *[
    _type == "product" &&
    locale == $locale &&
    !wasDeleted &&
    !isDraft
  ] {
    ${catalogueProductFragment}
  }
`

export const productExistsQuery = groq`
  *[
    _type == "product" &&
    _id == $id
  ][0] {
    _id,
  }
`
