import cx from 'classnames'

import Icon from './icon'

interface ArrowButtonIconProps {
  isOrderFirst?: boolean
}

export const ArrowButtonIcon = ({ isOrderFirst }: ArrowButtonIconProps) => {
  return (
    <span
      className={cx('inline-flex items-center justify-center w-[28px]', {
        'order-first mr-2': isOrderFirst,
        'ml-5': !isOrderFirst,
      })}
    >
      <Icon
        id="arrow-button-icon"
        name="ArrowRight"
        viewBox="0 0 28 28"
        className="block"
      />
    </span>
  )
}
