import { useState, useContext } from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'

import { SanityContentBlockLinkStyle } from '@data/sanity/queries/types/content'
import { SanityProductVariantFragment } from '@data/sanity/queries/types/product'
import { serializers } from '@lib/serializers'
import { StringsContext } from '@lib/strings'

import AddToCartButton from '@components/buttons/add-to-cart-button'
import Alert from '@components/alert'

interface AddToCartButtonCardProps {
  node: {
    text?: string
    style: SanityContentBlockLinkStyle
    productVariant: SanityProductVariantFragment
  }
}

const AddToCartButtonCard = ({ node }: AddToCartButtonCardProps) => {
  const [isAddToCartError, setIsAddToCartError] = useState(false)
  const strings = useContext(StringsContext)

  const {
    productVariant,
    text,
    style: { style, isSmall, isLarge, isBlock },
  } = node

  const handleAddToCartError = () => {
    setIsAddToCartError(true)
  }

  return (
    <>
      <AddToCartButton
        productVariant={productVariant}
        primary={style === 'primary'}
        secondary={style === 'secondary'}
        tertiary={style === 'tertiary'}
        white={style === 'white'}
        gray={style === 'gray'}
        small={isSmall}
        large={isLarge}
        className={cx('btn', { 'w-full': isBlock })}
        onAddToCartError={handleAddToCartError}
      >
        {text}
      </AddToCartButton>

      {isAddToCartError && (
        <div key="error" className="mt-2">
          <Alert error>
            <BlockContent
              renderContainerOnSingleChild
              className="rc rc-alert rc-error"
              blocks={strings.cartAddToCartErrorMessage}
              serializers={serializers}
            />
          </Alert>
        </div>
      )}
    </>
  )
}

export default AddToCartButtonCard
