import cx from 'classnames'
import { useContext, useMemo } from 'react'

import { hasObject } from '@lib/helpers'
import { StringsContext } from '@lib/strings'
import { getPageUrl, PageType } from '@lib/routes'
import { CartContext } from '@lib/cart/context'
import { LineItem } from '@lib/cart/types'

import SimpleLink from '@components/simple-link'
import Photo from '@components/photo'
import ProductPrice from '@blocks/product/product-price'
import ProductCounter from '@blocks/product/product-counter'

interface CartItemProps {
  item: LineItem
  inModal: boolean
  className?: string
}

const CartItem = ({ item, inModal, className }: CartItemProps) => {
  const strings = useContext(StringsContext)
  const { toggleCart, updateCartItem, removeItemFromCart } =
    useContext(CartContext)

  const photos = useMemo(() => {
    const defaultPhoto = item.photos.cart?.find(
      (photo) => typeof photo === 'string' || !photo.forOption
    )
    const variantPhoto = item.photos.cart?.find((photo) => {
      if (typeof photo === 'string') {
        return false
      }

      if (!photo.forOption) {
        return false
      }

      const option = {
        name: photo.forOption.split(':')[0],
        value: photo.forOption.split(':')[1],
      }

      return option.value && hasObject(item.options, option)
    })

    return variantPhoto ?? defaultPhoto
  }, [item.photos, item.options])

  const productUrl = item.product.slug
    ? getPageUrl(PageType.PRODUCT, item.product.slug, {
        parameters: {
          variant: `${item.id}`,
        },
      })
    : null

  return (
    <div className={cx('flex relative', className)}>
      {photos && typeof photos === 'string' && (
        <figure className="flex-shrink-0 relative m-0 w-1/4 sm:w-1/3 max-w-[10rem] photo-figure">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={photos}
            sizes="(min-width: 768px) 400px, 35vw"
            alt={item.product.title}
            className="block overflow-hidden"
          />
        </figure>
      )}
      {photos && typeof photos !== 'string' && (
        <Photo
          image={photos.default}
          layout="responsive"
          sizes="(min-width: 768px) 400px, 35vw"
          className="flex-shrink-0 relative m-0 w-1/4 sm:w-1/3 max-w-[10rem]"
        />
      )}

      <div className="flex flex-col flex-grow pl-4">
        <div
          className={cx('mb-3', {
            'sm:flex': inModal,
          })}
        >
          <div className="sm:pr-12 flex-1 m-0">
            <div className="mb-1 text-xs leading-relaxed tracking-wider font-semibold uppercase">
              {item.title}
            </div>
            <h2 className="mb-2 sm:mb-4 text-xl sm:text-3xl">
              {!productUrl && (
                <span className="block text-current after:block after:absolute after:inset-0 after:z-10">
                  {item.product.title}
                </span>
              )}
              {productUrl && (
                <SimpleLink
                  href={productUrl}
                  className="block text-current after:block after:absolute after:inset-0 after:z-10"
                  onClick={() => toggleCart(false)}
                  onBeforeInput={() => toggleCart(false)}
                  tabIndex={0}
                  role="link"
                >
                  {item.product.title}
                </SimpleLink>
              )}
            </h2>
          </div>
          <ProductPrice
            price={item.comparePrice ? item.comparePrice : item.price}
            inCartItem
            className="ml-auto"
          />
        </div>

        <div
          className={cx('flex relative z-10 mt-4', {
            'justify-between': inModal,
          })}
        >
          <ProductCounter
            isSmall
            id={`${item.id}`}
            defaultCount={item.quantity}
            onUpdate={(quantity) => updateCartItem(item.lineId, quantity)}
            className={cx({
              'mr-6': !inModal,
            })}
          />
          <button
            onClick={() => removeItemFromCart(item.lineId)}
            className={cx('text-xs font-semibold opacity-80 hover:opacity-100')}
          >
            {strings.buttonRemove}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CartItem
