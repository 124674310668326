import CustomLink from '@components/link'

interface LinkProps {
  mark: Record<string, unknown>
  children: string[]
}

const Link = ({ mark, children }: LinkProps) => (
  <CustomLink link={{ ...mark }}>{children[0]}</CustomLink>
)

export default Link
