import { useContext } from 'react'
import cx from 'classnames'

import { CartContext } from '@lib/cart/context'
import { useCartTotals } from '@lib/cart/hooks'
import { DiscountContext } from '@lib/discount'
import { StringsContext } from '@lib/strings'

import CartDiscount from './discount'
import CartSubtotal from './subtotal'
import CartTotal from './total'

interface CartSummaryProps {
  className?: string
}

const CartSummary = ({ className }: CartSummaryProps) => {
  const { productDiscountTotal, totalDiscount, subTotal, total } =
    useCartTotals()
  const { cart } = useContext(CartContext)
  const { cartDiscountItems } = useContext(DiscountContext)
  const strings = useContext(StringsContext)

  const automaticDiscount = cart?.automaticDiscount ?? null

  return (
    <div
      className={cx('border-t border-pageText py-6 sm:py-8 sm:px-4', className)}
    >
      <CartSubtotal price={subTotal} />

      {productDiscountTotal > 0 && (
        <CartDiscount
          title={strings.cartProductDiscount}
          amount={productDiscountTotal}
          className="mt-3"
        />
      )}

      {automaticDiscount.amount > 0 && (
        <CartDiscount
          title={automaticDiscount.title}
          amount={automaticDiscount.amount}
          className="mt-3"
        />
      )}

      {cartDiscountItems?.map(
        ({ id, title, amount, quantity, discountValuePercent }) => (
          <CartDiscount
            key={id}
            title={title}
            amount={amount}
            quantity={quantity}
            percent={discountValuePercent}
            className="mt-3"
          />
        )
      )}

      <CartTotal price={total - totalDiscount} className="mt-3" />
    </div>
  )
}

export default CartSummary
