import {
  AttributeInput,
  CartFragmentFragment,
} from '@data/shopify/storefront/types'
import { getSanityClient } from '@lib/sanity/client'
import { Locale } from '@lib/language'
import { getProductVariants } from '@lib/sanity/product'
import { Cart, CartFormValues, LineItem } from '@lib/cart/types'
import { CartFragmentFragmentLine } from './graphql/cart'
import { parseShopifyGlobalId } from './client'

export const SHOPIFY_ATTRIBUTE_TAX_VAT_ID = '_vat_id'
export const SHOPIFY_ATTRIBUTE_TAX_EXEMPT = '_tax_exempt'

/**
 * Gets tax-related cart attributes.
 */
export const getTaxCartAttributes = (
  cartFormValues: CartFormValues,
  taxExempt: boolean
) => {
  const customAttributes: AttributeInput[] = []

  if (cartFormValues.vatId) {
    // Set VAT ID checkout attribute
    customAttributes.push({
      key: SHOPIFY_ATTRIBUTE_TAX_VAT_ID,
      value: cartFormValues.vatId,
    })

    // Set tax exemption checkout attribute
    customAttributes.push({
      key: SHOPIFY_ATTRIBUTE_TAX_EXEMPT,
      value: taxExempt ? 'yes' : 'no',
    })
  }

  return customAttributes
}

/**
 * Parses Shopify line items.
 */
const parseShopifyCartLineItems = async (
  lineItems: CartFragmentFragmentLine[],
  locale: Locale
): Promise<LineItem[]> => {
  // Read product variants in cart
  const cartVariantIds = lineItems
    .map((lineItem) =>
      lineItem.merchandise.id
        ? parseShopifyGlobalId(`${lineItem.merchandise.id}`)
        : null
    )
    .filter(Boolean) as number[]
  const sanityClient = getSanityClient()
  const productVariants = await getProductVariants(
    sanityClient,
    locale,
    cartVariantIds
  )

  return lineItems
    .map((lineItem) => {
      const variantId = lineItem.merchandise.id
        ? parseShopifyGlobalId(`${lineItem.merchandise.id}`)
        : null

      const variant = variantId
        ? productVariants.find(({ id }) => id === variantId)
        : null

      if (!variant) {
        return
      }

      const variantLineItem: LineItem = {
        lineId: lineItem.id,
        quantity: lineItem.quantity,
        ...variant,
      }

      return variantLineItem
    })
    .filter(Boolean) as LineItem[]
}

/**
 * Parses Shopify cart.
 */
export const parseShopifyCart = async (
  locale: Locale,
  shopifyCart?: CartFragmentFragment
): Promise<Cart | undefined> => {
  if (!shopifyCart) {
    return
  }

  const lineItems = await parseShopifyCartLineItems(
    shopifyCart.lines.edges.map((edge) => edge.node),
    locale
  )

  const subTotal = shopifyCart.estimatedCost.subtotalAmount
    ? Number(shopifyCart.estimatedCost.subtotalAmount.amount) * 100
    : 0

  const automaticDiscount = shopifyCart.lines.edges.reduce(
    (result, edge) => {
      const discountAllocation = edge.node.discountAllocations[0]

      if (
        discountAllocation &&
        'title' in discountAllocation &&
        'discountedAmount' in discountAllocation
      ) {
        return {
          title: discountAllocation.title,
          amount:
            result.amount +
            parseFloat(discountAllocation.discountedAmount.amount) * 100,
        }
      }

      return result
    },
    {
      title: '',
      amount: 0,
    }
  )

  const total = subTotal - automaticDiscount.amount

  return {
    id: shopifyCart.id,
    lineItems,
    automaticDiscount,
    discountCodes: shopifyCart.discountCodes.map(({ code }) => code),
    subTotal,
    total,
    webUrl: `${shopifyCart.checkoutUrl}`,
  }
}
