import cx from 'classnames'
import { motion, Variants } from 'framer-motion'
import { useState, ReactNode } from 'react'

import Icon from '@components/icon'

interface AccordionProps {
  id: string
  title?: ReactNode
  className?: string
  innerClassName?: string
  children?: ReactNode
}

const accordionContentVariants: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  closed: {
    opacity: 0,
    height: 0,
  },
}

const Accordion = ({
  id,
  title,
  className,
  innerClassName,
  children,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={cx('border-t border-pageText', className)}>
      <button
        onClick={() => setIsOpen((isOpen) => !isOpen)}
        aria-expanded={isOpen}
        aria-controls={`accordion-${id}`}
        className="text-left flex justify-between items-center px-0 py-4 w-full bg-transparent font-semibold text-lg leading-snug"
      >
        {title}
        <div className="ml-2 w-5 h-5">
          <Icon
            id={`accordion-icon-${id}`}
            name="ChevronDown"
            viewBox="0 0 28 28"
            className={cx(
              'transition-transform duration-300 ease-custom-1 text-pageText h-full',
              {
                'transform rotate-180': isOpen,
              }
            )}
          />
        </div>
      </button>

      <motion.div
        id={`accordion-${id}`}
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        exit="closed"
        variants={accordionContentVariants}
        transition={{
          duration: 0.5,
          ease: [0.19, 1.0, 0.22, 1.0],
        }}
        className="overflow-hidden"
      >
        <div className={cx('m-0 pb-12', innerClassName)}>{children}</div>
      </motion.div>
    </div>
  )
}

export default Accordion
