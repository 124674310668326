import cx from 'classnames'

import { SanityContentBlockLinkStyle } from '@data/sanity/queries/types/content'
import { SanityImageFragment } from '@data/sanity/queries/types/image'
import { SanityVideo } from '@data/sanity/queries/types/video'

import VideoButton from '@components/buttons/video-button'

interface VideoButtonCardProps {
  node: {
    id?: string
    text?: string
    style: SanityContentBlockLinkStyle
    video: SanityVideo
    thumbnail?: SanityImageFragment
  }
}

const VideoButtonCard = ({ node }: VideoButtonCardProps) => {
  const {
    text,
    video,
    style: { style, isBlock },
  } = node

  return (
    <VideoButton
      video={video}
      primary={style === 'primary'}
      secondary={style === 'secondary'}
      tertiary={style === 'tertiary'}
      white={style === 'white'}
      gray={style === 'gray'}
      primaryPlay={style === 'primary-play'}
      whitePlay={style === 'white-play'}
      className={cx('btn', { 'w-full': isBlock })}
    >
      {text}
    </VideoButton>
  )
}

export default VideoButtonCard
