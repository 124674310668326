import { Locale } from './language'

export type Keyed<T> = { _key: string } & T

export interface ErrorMessages {
  [key: string]: string
}

export const Keys = {
  ENTER: 13,
  SPACE: 32,
  LEFT: 37,
  RIGHT: 39,
  UP: 38,
  DOWN: 40,
}

export const isBrowser = typeof window !== 'undefined'

/**
 * Checks if an object is found in another array of objects.
 */
export function hasObject(recs?: any[], vals?: any[] | any) {
  if (!recs) {
    return false
  }

  return recs.some((obj) => {
    for (const x in obj) {
      if (vals && x in vals && obj[x] != (vals as Record<string, unknown>)[x]) {
        return false
      }
    }

    return true
  })
}

/**
 * Keeps a number within a range.
 */
export function clampRange(value: number, min = 0, max = 1) {
  return value < min ? min : value > max ? max : value
}

/**
 * Wraps a number around minimum and maximum value.
 */
export function wrap(value: number, length: number) {
  if (value < 0) {
    value = length + (value % length)
  }

  if (value >= length) {
    return value % length
  }

  return value
}

/**
 * Formats a value by adding thousands separators.
 */
const addThousandSeparators = (value: string, thousandSeparator: string) => {
  if (!thousandSeparator) {
    return value
  }

  return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
}

/**
 * Gets price from value in minor units and adds tax. Adds trailing zeros if needed.
 */
export const getPrice = (
  minorUnits: number,
  taxRate: number,
  hasTrailingZeros = false,
  thousandSeparator = ','
) => {
  const price = (minorUnits / 100) * (1 + taxRate)

  if (!hasTrailingZeros && price % 1 === 0) {
    return addThousandSeparators(`${price}`, thousandSeparator)
  }

  const parts = price.toFixed(2).split('.')
  parts[0] = addThousandSeparators(parts[0], thousandSeparator)

  return `${parts.join('.')}`
}

/**
 * Parses optional page request parameter.
 */
export function parseOptionalParameter<T = string>(
  parameter?: T | T[] | null
): T | undefined {
  if (!parameter || (Array.isArray(parameter) && parameter.length === 0)) {
    return
  }

  return parseRequiredParameter<T>(parameter)
}

/**
 * Parses optional page request parameter array.
 */
export function parseOptionalParameters<T = string>(
  parameter?: T | T[] | null
): T[] | undefined {
  if (!parameter || (Array.isArray(parameter) && parameter.length === 0)) {
    return
  }

  return parseRequiredParameters<T>(parameter)
}

/**
 * Parses required page request parameter.
 */
export function parseRequiredParameter<T = string>(parameter: T | T[]): T {
  return Array.isArray(parameter) ? parameter[0] : parameter
}

/**
 * Parses required page request parameter array.
 */
export function parseRequiredParameters<T = string>(parameter: T | T[]): T[] {
  return Array.isArray(parameter) ? parameter : [parameter]
}

/**
 * Converts an enum into an object.
 */
export function enumToObject<T>(
  enumeration: T
): { [P in keyof T]: T[P] }[keyof T] {
  return enumeration as unknown as T[keyof T]
}

/**
 * Parses JSON string into an object.
 */
export const parseJson = (json: string): Record<string, unknown> => {
  try {
    return JSON.parse(json)
  } catch (_) {
    return {}
  }
}

/**
 * Compares numbers for sorting.
 */
export const compareNumbers = (number1: number, number2: number) =>
  number1 - number2

/**
 * Compares strings for sorting.
 */
export const compareStrings = (string1: string, string2: string) =>
  string1.localeCompare(string2)

/**
 * Filters duplicates from an array.
 */
export function filterDuplicates<T>(value: T, index: number, array: T[]) {
  return array.indexOf(value) === index
}

/**
 * Determines if 2 variables are equal using JSON representation.
 */
export const isEqual = (variable1: unknown, variable2: unknown) =>
  JSON.stringify(variable1) === JSON.stringify(variable2)

/**
 * Determines is user agent matches Apple Safari.
 */
export const isMobileSafari = () => {
  if (!isBrowser) {
    return false
  }

  return (
    !!navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    !!navigator.userAgent.match(/AppleWebKit/)
  )
}

/**
 * Generates all combinations from multiple arrays.
 * E.g., getAllCombinations(['a', 'b'], ['1', '2']) returns [['a', '1'], ['a', '2'], ['b', '1'], ['b', '2']].
 */
export const getAllCombinations = (...arrays: string[][]): string[][] => {
  const initialValue: string[][] = [[]]

  return [...arrays].reduce(
    (resultArrays, array) =>
      resultArrays
        .map((resultArray) =>
          array.map((arrayValue) => resultArray.concat(arrayValue))
        )
        .reduce(
          (newResultArrays, arraysItem) => newResultArrays.concat(arraysItem),
          []
        ),
    initialValue
  )
}

/**
 * Gets formatted date by date string and locale.
 */
export const getFormattedDate = (
  date: string,
  locale: Locale,
  formatOptions?: Intl.DateTimeFormatOptions
) => {
  const dateTimeFormat = new Intl.DateTimeFormat(
    locale,
    formatOptions ?? {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
  )

  return dateTimeFormat.format(new Date(date))
}

/**
 * Gets the first existing value from an array of values.
 */
export function getFirstValue<T = string>(
  values: (T | undefined)[]
): T | undefined {
  return values.filter(Boolean)[0]
}

export const canUseDom = () =>
  typeof window !== 'undefined' &&
  !!window.document &&
  !!window.document.createElement
