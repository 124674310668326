import { useCallback, useContext, useState } from 'react'
import { NextApiHandler } from 'next'
import axios, { AxiosResponse } from 'axios'
import useSWR from 'swr'
import { IronSessionOptions } from 'iron-session'
import { withIronSessionApiRoute } from 'iron-session/next'

import { ParseResults, ParseStatus } from './shopify/graphql/client'
import { createUser } from './shopify/graphql/customer'
import { FacebookEvent, triggerFacebookEvent } from './facebook'
import { ErrorMessages } from './helpers'
import { LanguageContext, Locale } from './language'
import { ShopContext } from './shop'
import { SiteContext } from './site'
import { UserAddress, UserProduct } from './user'

export interface SignupFormValues {
  firstName: string
  lastName: string
  email: string
  password: string
}

export interface LoginFormValues {
  email: string
  password: string
}

export interface PasswordRecoveryFormValues {
  email: string
}

export interface UserCookie {
  isLoggedIn: boolean
  email?: string
  token?: string
  addresses?: UserAddress[]
  ownedProducts?: UserProduct[]
  subscriberId?: number
  subscriberHash?: string
  activeSubscriptionProductIds?: number[]
}

export interface ApiAuthResponse extends ParseResults {
  user?: UserCookie
}

interface LogoutPayload {
  token?: string
}

export const sessionOptions: IronSessionOptions = {
  password: {
    1: process.env.SECRET_COOKIE_PASSWORD ?? '',
  },
  cookieName: 'nhl_auth',
}

/**
 * Gets user session wrapper for API pages.
 */
export const withSessionRoute = (handler: NextApiHandler) =>
  withIronSessionApiRoute(handler, sessionOptions)

/**
 * User data and mutation hook.
 */
export const useUser = () => {
  const { locale } = useContext(LanguageContext)

  const { data: user, mutate: mutateUser } = useSWR<UserCookie>(
    {
      url: '/api/auth/user',
      localeHeader: locale,
    },
    async ({ url, localeHeader }: { url: string; localeHeader: Locale }) => {
      const response = await axios.get<UserCookie>(url, {
        headers: {
          'X-Locale': localeHeader,
        },
      })

      return response.data
    },
    {
      errorRetryCount: 3,
    }
  )

  return {
    user,
    mutateUser,
  } as const
}

/**
 * Returns a method that logs in a user.
 */
export const useLoginUser = () => {
  const { locale } = useContext(LanguageContext)
  const { mutateUser } = useUser()

  return useCallback(
    async (payload: LoginFormValues) => {
      try {
        const apiLoginResponse = await axios.post<
          ApiAuthResponse,
          AxiosResponse<ApiAuthResponse>,
          LoginFormValues
        >('/api/auth/login', payload, {
          headers: {
            'Content-Type': 'application/json',
            'X-Locale': locale,
          },
        })
        const { user, ...parseResults } = apiLoginResponse.data
        mutateUser(user)

        return parseResults
      } catch (error) {
        console.log(error)

        return {
          fieldErrors: {},
          errorCount: 0,
          status: ParseStatus.UNKNOWN_ERROR,
        }
      }
    },
    [locale, mutateUser]
  )
}

/**
 * Returns a method that logs out a user.
 */
export const useLogoutUser = () => {
  const { user, mutateUser } = useUser()
  const { locale } = useContext(LanguageContext)

  return useCallback(
    async (onSuccess?: () => void) => {
      const payload: LogoutPayload = { token: user?.token }
      const apiLogoutResponse = await axios.post<
        ApiAuthResponse,
        AxiosResponse<ApiAuthResponse>,
        LogoutPayload
      >('/api/auth/logout', payload, {
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      })

      // Update SWR cache and redirect to the login page
      mutateUser(apiLogoutResponse.data.user)

      if (onSuccess) {
        onSuccess()
      }
    },
    [locale, mutateUser, user?.token]
  )
}

/**
 * User signup form submit handler hook.
 */
export const useSubmitSignupForm = () => {
  const { locale } = useContext(LanguageContext)
  const { settings } = useContext(SiteContext)
  const { shopifyStorefrontClient } = useContext(ShopContext)
  const loginUser = useLoginUser()

  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({})

  const handleSignupSubmit = useCallback(
    async (values: SignupFormValues, onSuccess?: () => void) => {
      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsLoading(true)
      setIsError(false)

      // Create user
      const createUserResult = await createUser(shopifyStorefrontClient, values)
      setErrorMessages(createUserResult.fieldErrors)

      if (createUserResult.status !== ParseStatus.OK) {
        setIsError(true)
      }

      if (
        createUserResult.status === ParseStatus.OK &&
        createUserResult.errorCount === 0
      ) {
        const { email, password } = values

        if (settings?.facebookEvents) {
          await triggerFacebookEvent(
            FacebookEvent.COMPLETE_REGISTRATION,
            locale
          )
        }

        // Login after creating user
        const loginUserResult = await loginUser({
          email,
          password,
        })

        setErrorMessages(loginUserResult.fieldErrors)

        if (loginUserResult.status !== ParseStatus.OK) {
          setIsError(true)
        }

        if (
          loginUserResult.status === ParseStatus.OK &&
          loginUserResult.errorCount === 0 &&
          onSuccess
        ) {
          onSuccess()
        }
      }

      setIsLoading(false)
    },
    [locale, loginUser, settings?.facebookEvents, shopifyStorefrontClient]
  )

  return [isError, isLoading, errorMessages, handleSignupSubmit] as const
}
