import { useRouter } from 'next/router'
import queryString from 'query-string'
import {
  Dispatch,
  ForwardedRef,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import {
  canUseDom,
  isBrowser,
  parseOptionalParameters,
  parseRequiredParameter,
} from './helpers'

export interface UrlParameter {
  name: string
  value: string | string[] | null
}

/**
 * React currently throws a warning when using useLayoutEffect on the server. To
 * get around it, we can conditionally useEffect on the server (no-op) and
 * useLayoutEffect in the browser. We occasionally need useLayoutEffect to
 * ensure we don't get a render flash for certain operations, but we may also
 * need affected components to render on the server. One example is when setting
 * a component's descendants to retrieve their index values.
 *
 * Important to note that using this hook as an escape hatch will break the
 * eslint dependency warnings unless you rename the import to `useLayoutEffect`.
 * Use sparingly only when the effect won't effect the rendered HTML to avoid
 * any server/client mismatch.
 *
 * If a useLayoutEffect is needed and the result would create a mismatch, it's
 * likely that the component in question shouldn't be rendered on the server at
 * all, so a better approach would be to lazily render those in a parent
 * component after client-side hydration.
 *
 * https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
 * https://github.com/reduxjs/react-redux/blob/master/src/utils/useIsomorphicLayoutEffect.js
 *
 * @param effect
 * @param deps
 */
export const useIsomorphicLayoutEffect = canUseDom()
  ? useLayoutEffect
  : useEffect

/**
 * Previous value hook.
 */
export function usePrevious<T>(value: T) {
  const previousValue = useRef<T>()

  useEffect(() => {
    previousValue.current = value
  }, [value])

  return previousValue.current
}

/**
 * Client-side mount hook.
 */
export function useHasMounted() {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return hasMounted
}

/**
 * Local storage state variable hook.
 * Syncs state to local storage so that it persists through a page refresh.
 */
export function useLocalStorageState<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      // Read value from local storage
      const item = window.localStorage.getItem(key)

      if (item) {
        return JSON.parse(item)
      }
    } catch (error) {
      console.log(error)
    }

    return initialValue
  })

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (value) => {
      try {
        const newValue = value instanceof Function ? value(storedValue) : value

        setStoredValue(newValue)

        // Save value to local storage
        window.localStorage.setItem(key, JSON.stringify(newValue))
      } catch (error) {
        console.log(error)
      }
    },
    [key, storedValue]
  )

  return [storedValue, setValue] as const
}

/**
 * Window size hook that listens to resize event.
 */
export function useWindowSize() {
  function getSize() {
    return {
      width: isBrowser ? window.innerWidth : 0,
      height: isBrowser ? window.innerHeight : 0,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isBrowser) {
      return
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

/**
 * Gets and updates multiple URL parameters.
 */
export const useUrlParameters = (
  initialParameters: UrlParameter[]
): [UrlParameter[], (parameters: UrlParameter[]) => void] => {
  const router = useRouter()

  let currentParameters = initialParameters

  // If query parameters are present, update current parameters
  if (Object.keys(router.query).length > 0) {
    currentParameters = initialParameters.map((parameter) => {
      if (!router.query[parameter.name]) {
        return parameter
      }

      const newParameter: UrlParameter = {
        ...parameter,
        value: parseRequiredParameter(router.query[parameter.name]) ?? '',
      }

      return newParameter
    })
  }

  // Update query parameters on change
  const setCurrentParameters = useCallback(
    (parameters: UrlParameter[]) => {
      const slugs = parseOptionalParameters(router.query?.slug) ?? []
      const currentPath = ([] as string[]).concat(slugs).join('/')

      // Remove parameters that match initial parameters
      const filteredParameters = parameters.filter(
        ({ name, value }) =>
          value !==
          initialParameters.find(
            (initialParameter) => initialParameter.name === name
          )?.value
      )

      const urlParameters = filteredParameters.reduce<
        Record<string, string[] | undefined>
      >((result, { name, value }) => {
        result[name] = value && Array.isArray(value) ? value : value?.split(',')

        return result
      }, {})
      const urlQueryString = queryString.stringify(urlParameters, {
        arrayFormat: 'comma',
      })

      // Replace current URL with new parameters
      router.replace(
        `${currentPath}${urlQueryString ? `?${urlQueryString}` : ''}`,
        undefined,
        { shallow: true, scroll: false }
      )
    },
    [initialParameters, router]
  )

  return [currentParameters, setCurrentParameters]
}

/**
 * Media query hook.
 * @see https://observablehq.com/@werehamster/avoiding-hydration-mismatch-when-using-react-hooks
 */
export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState<boolean | undefined>()

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query)
    const listener = () => setMatches(!!mediaQueryList.matches)
    listener()
    mediaQueryList.addEventListener('change', listener)

    return () => mediaQueryList.removeEventListener('change', listener)
  }, [query])

  return matches
}

export const useResizeObserver = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback: (target: T, entry: ResizeObserverEntry) => void
) => {
  useIsomorphicLayoutEffect(() => {
    const element = ref?.current

    if (!element) {
      return
    }

    const observer = new ResizeObserver((entries) => {
      callback(element, entries[0])
    })

    observer.observe(element)

    return () => {
      observer.disconnect()
    }
  }, [callback, ref])
}

export const useElementSize = <T = HTMLElement>() => {
  const elementRef = useRef<T | null>(null)
  const [size, setSize] = useState<
    readonly [number, number] | readonly [null, null]
  >([null, null])

  useResizeObserver(elementRef as RefObject<HTMLElement>, () => {
    // Assume a div element is given
    const element = (elementRef as RefObject<HTMLDivElement>).current

    if (!element) {
      return
    }

    // Check if size is set and hasn't changed
    if (
      size[0] !== null &&
      size[1] !== null &&
      Math.round(element.offsetWidth) === Math.round(size[0]) &&
      Math.round(element.offsetHeight) === Math.round(size[1])
    ) {
      return
    }

    setSize([element.offsetWidth, element.offsetHeight])
  })

  return [elementRef, size] as const
}

export const useForwardRef = <T>(ref: ForwardedRef<T>, initialValue: T) => {
  const targetRef = useRef<T>(initialValue)

  useEffect(() => {
    if (!ref) {
      return
    }

    if (typeof ref === 'function') {
      ref(targetRef.current)
      return
    }

    ref.current = targetRef.current
  }, [ref])

  return targetRef
}
