import groq from 'groq'

import { imageFragment } from './image'
import { linkPageFragment } from './link'
import { videoFragment } from './video'

export const simpleContentFragment = groq`
  ...,
  markDefs[] {
    ...,
    _type == "link" => {
      url,
      isButton,
      styles{
        style,
        isSmall,
        isLarge,
        isBlock
      },
      page->{
        ${linkPageFragment}
      },
    },
  },
`

export const complexContentFragment = groq`
  ...,
  markDefs[] {
    ...,
    _type == "link" => {
      url,
      isButton,
      styles{
        style,
        isSmall,
        isLarge,
        isBlock
      },
      page->{
        ${linkPageFragment}
      },
    },
  },
  _type == "figure" => {
    ${imageFragment}
  },
  _type == 'quote' => {
    text,
    author,
    textAlignment,
  },
  _type == "videoButton" => {
    text,
    video {
      ${videoFragment}
    },
    style {
      style,
      isBlock,
    },
  },
  _type == "addToCartButton" => {
    text,
    style {
      style,
      isSmall,
      isLarge,
      isBlock,
    },
    productVariant->{
      _id,
      locale,
      price,
      inStock,
      "id": variantID,
    },
  },
`
