import Script from 'next/script'

interface GoogleTagScriptProps {
  id?: string
}

const GoogleTagScript = ({ id }: GoogleTagScriptProps) => {
  if (!id) {
    return null
  }

  return (
    <>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${id}');
        `}
      </Script>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${id}`}
        strategy="afterInteractive"
      />
    </>
  )
}

export default GoogleTagScript
