import cx from 'classnames'
import { AnchorHTMLAttributes } from 'react'

import { ArrowButtonIcon } from '@components/arrow-button-icon'
import { ArrowButtonProps, getArrowButtonStyles } from './button'

const ArrowButtonLink = ({
  href,
  target,
  children,
  outline,
  className,
}: ArrowButtonProps & AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <a
      href={href}
      target={target}
      className={cx(
        getArrowButtonStyles({
          outline,
        }),
        className
      )}
    >
      {children}
      {<ArrowButtonIcon isOrderFirst={!outline} />}
    </a>
  )
}

export default ArrowButtonLink
