import { createContext, ReactNode, useMemo } from 'react'

import {
  SanitySiteFragment,
  SanitySiteStrings,
} from '@data/sanity/queries/types/site'

const emptySiteStrings: SanitySiteStrings = {
  loadingPageTitle: '',
  languageSwitch: '',
  cartLink: '',
  featuredProducts: '',
  skipToContent: '',
  goHomeLabel: '',
  mobileMenuLabel: '',
  productLowStock: '',
  productOutOfStock: '',
  productPriceLabel: '',
  productColorOptionLabel: '',
  productDiscountText: '',
  productIncreaseQuantity: '',
  productDecreaseQuantity: '',
  productEnterQuantity: '',
  cartTitle: '',
  cartClose: '',
  cartEmpty: '',
  cartVatSectionTitle: '',
  cartVatIdInputPlaceholder: '',
  cartVatIdError: '',
  cartInvalidVatIdError: '',
  cartCommentSectionTitle: '',
  cartCommentInputPlaceholder: '',
  cartDiscount: '',
  cartProductDiscount: '',
  cartSubtotal: '',
  cartTotal: '',
  cartSubmit: '',
  cartAddToCartErrorMessage: [],
  carouselCounterText: '',
  carouselLeftArrowLabel: '',
  carouselRightArrowLabel: '',
  carouselDotLabel: '',
  buttonAddToCart: '',
  buttonRemove: '',
  buttonAccept: '',
  buttonLearnMore: '',
  buttonTryAgain: '',
  buttonLoadMore: '',
  buttonUnavailable: '',
  buttonWaiting: '',
  buttonLoading: '',
  buttonAdding: '',
  buttonUpdating: '',
  buttonSubmitting: '',
  buttonEdit: '',
  buttonDelete: '',
  buttonCancel: '',
  emailAddress: '',
  emailMissing: '',
  emailInvalid: '',
  productWaitlistSubmit: '',
  productWaitlistSuccess: '',
  productWaitlistError: '',
}

export const StringsContext = createContext<SanitySiteStrings>(emptySiteStrings)

interface StringsContextProviderProps {
  site: SanitySiteFragment
  children: ReactNode
}

export const StringsContextProvider = ({
  site,
  children,
}: StringsContextProviderProps) => {
  const strings = useMemo(
    () => ({ ...emptySiteStrings, ...site.siteStrings }),
    [site.siteStrings]
  )

  return (
    <StringsContext.Provider value={strings}>
      {children}
    </StringsContext.Provider>
  )
}
