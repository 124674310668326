import { ButtonHTMLAttributes, ReactNode, useContext } from 'react'

import { SanityVideo } from '@data/sanity/queries/types/video'
import { SiteContext } from '@lib/site'

import Button, { ButtonProps } from './button'
import PlayButton from './play-button'

export type VideoButtonProps = {
  video: SanityVideo
  children?: ReactNode
  primaryPlay?: boolean
  whitePlay?: boolean
} & Omit<ButtonProps, 'small'> &
  ButtonHTMLAttributes<HTMLButtonElement>

const VideoButton = ({
  video,
  children,
  primary,
  secondary,
  tertiary,
  gray,
  white,
  primaryPlay,
  whitePlay,
  className,
}: VideoButtonProps) => {
  const { toggleVideoModal } = useContext(SiteContext)

  const openModal = () => toggleVideoModal(true, video)

  if (primaryPlay || whitePlay) {
    return (
      <PlayButton onClick={openModal} white={whitePlay} className={className}>
        {children}
      </PlayButton>
    )
  }

  return (
    <Button
      onClick={openModal}
      primary={primary}
      secondary={secondary}
      tertiary={tertiary}
      gray={gray}
      white={white}
      className={className}
    >
      {children}
    </Button>
  )
}

export default VideoButton
