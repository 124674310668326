import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'

import { CustomerErrorCode } from '@data/shopify/storefront/types'
import { ErrorMessages } from '@lib/helpers'
import { getLocaleVariable, Locale } from '@lib/language'
import {
  getShopifyAdminApiUrl,
  getShopifyDomain,
  getShopifyStorefrontApiUrl,
} from '../client'

export type ShopifyClient = ApolloClient<NormalizedCacheObject>

export enum ParseStatus {
  OK = 'ok',
  INVALID_CREDENTIALS = 'invalid_credentials',
  MISSING_CREDENTIALS = 'missing_credentials',
  UNKNOWN_ERROR = 'unknown_error',
}

export interface ParseResults {
  fieldErrors: ErrorMessages
  errorCount: number
  status: ParseStatus
}

interface CustomerUserError {
  __typename?: 'CustomerUserError'
  code?: CustomerErrorCode | null
  field?: Array<string> | null
  message: string
}

/**
 * Returns apollo client for Shopify Storefront GraphQL API.
 */
export const getShopifyStorefrontClient = (locale: Locale) => {
  const shopifyDomain = getShopifyDomain(locale)
  const shopifyStorefrontApiUrl = getShopifyStorefrontApiUrl(shopifyDomain)
  const shopifyStorefrontAccessToken =
    getLocaleVariable(locale, 'SHOPIFY_API_TOKEN') ?? ''

  return new ApolloClient({
    uri: `${shopifyStorefrontApiUrl}/graphql.json`,
    cache: new InMemoryCache(),
    headers: shopifyStorefrontAccessToken
      ? {
          'X-Shopify-Storefront-Access-Token': shopifyStorefrontAccessToken,
        }
      : {},
  })
}

/**
 * Returns apollo client for Shopify Admin GraphQL API.
 */
export const getShopifyAdminClient = (locale: Locale) => {
  const shopifyDomain = getShopifyDomain(locale)
  const shopifyAdminApiUrl = getShopifyAdminApiUrl(shopifyDomain)
  const shopifyAdminAccessToken =
    getLocaleVariable(locale, 'SHOPIFY_API_PASSWORD') ?? ''

  return new ApolloClient({
    uri: `${shopifyAdminApiUrl}/graphql.json`,
    cache: new InMemoryCache(),
    headers: shopifyAdminAccessToken
      ? {
          'X-Shopify-Access-Token': shopifyAdminAccessToken,
        }
      : {},
  })
}

/**
 * Gets field name from Shopify GraphQL API result error.
 */
export const getGrqphQLErrorFieldName = (errorField?: string[] | null) => {
  const count = errorField?.length ?? 0

  if (errorField && count > 0) {
    return errorField[count - 1]
  }
}

/**
 * Gets error field validation results.
 */
export const parseMutationResult = (
  customerUserErrors: CustomerUserError[]
): ParseResults => {
  const results: ParseResults = {
    fieldErrors: {},
    errorCount: customerUserErrors.length,
    status: ParseStatus.OK,
  }

  customerUserErrors.forEach((customerUserError) => {
    const fieldName = getGrqphQLErrorFieldName(customerUserError.field)

    if (fieldName) {
      results.fieldErrors[fieldName] = customerUserError.message
    }
  })

  return results
}
