import { type SanityClient } from '@sanity/client'

import { productPageQuery } from '@data/sanity/queries/page'
import {
  productCatalogueQuery,
  productVariantQuery,
  productVariantsQuery,
} from '@data/sanity/queries/product'
import { SanityProductPageQuery } from '@data/sanity/queries/types/page'
import {
  SanityLocaleQueryParameter,
  SanityProductSlugQueryParameter,
  SanityShopifyProductVariantIdQueryParameter,
  SanityShopifyProductVariantIdsQueryParameter,
} from '@data/sanity/queries/types/parameters'
import {
  SanityProductCatalogueQuery,
  SanityProductVariantQuery,
  SanityProductVariantsQuery,
} from '@data/sanity/queries/types/product'
import { Locale } from '@lib/language'
import { fetchQuery } from './client'
import {
  defaultModuleQueryParameters,
  SanityModuleQueryParameters,
} from './page'

/**
 * Fetches product page data based on product slug.
 */
export const getProductPage = async (
  sanityClient: SanityClient,
  locale: Locale,
  productSlug: string
) =>
  fetchQuery<
    SanityProductPageQuery,
    SanityLocaleQueryParameter &
      SanityProductSlugQueryParameter &
      SanityModuleQueryParameters
  >(sanityClient, productPageQuery, {
    ...defaultModuleQueryParameters,
    locale,
    productSlug,
  })

/**
 * Fetches a product variant based on its ID.
 */
export const getProductVariant = async (
  sanityClient: SanityClient,
  locale: Locale,
  shopifyProductVariantId: number
) =>
  fetchQuery<
    SanityProductVariantQuery | null,
    SanityLocaleQueryParameter & SanityShopifyProductVariantIdQueryParameter
  >(sanityClient, productVariantQuery, {
    locale,
    shopifyProductVariantId,
  })

/**
 * Fetches product variants based on a list of ID.
 */
export const getProductVariants = async (
  sanityClient: SanityClient,
  locale: Locale,
  shopifyProductVariantIds: number[]
) =>
  fetchQuery<
    SanityProductVariantsQuery,
    SanityLocaleQueryParameter & SanityShopifyProductVariantIdsQueryParameter
  >(sanityClient, productVariantsQuery, {
    locale,
    shopifyProductVariantIds,
  })

/**
 * Gets all product catalogue.
 */
export const getProductCatalogue = async (
  sanityClient: SanityClient,
  locale: Locale
) =>
  fetchQuery<SanityProductCatalogueQuery, SanityLocaleQueryParameter>(
    sanityClient,
    productCatalogueQuery,
    {
      locale,
    }
  )
