import cx from 'classnames'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import {
  HTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useContext,
} from 'react'

import { SanityLink } from '@data/sanity/queries/types/content'
import { useLogoutUser } from '@lib/auth'
import { getPageUrl, isRouteCollection, PageType } from '@lib/routes'
import { SiteContext } from '@lib/site'

import { ArrowButtonIcon } from './arrow-button-icon'
import ArrowButtonLink from './buttons/arrow-button-link'
import { getArrowButtonStyles, getButtonStyles } from './buttons/button'
import ButtonLink from './buttons/button-link'

interface LinkProps {
  link: SanityLink
  onClick?: () => void
  children?: ReactNode
  showCollectionCount?: boolean
}

const Link = ({
  link,
  children,
  tabIndex,
  onClick,
  className,
  showCollectionCount,
  'aria-label': ariaLabel,
}: LinkProps & HTMLAttributes<HTMLAnchorElement>) => {
  const { getProductCount } = useContext(SiteContext)
  const router = useRouter()
  const logoutUser = useLogoutUser()

  const pageType = link.page && (link.page.type as PageType)
  const pageSlug = link.page?.slug
  const isCollection = !!pageType && isRouteCollection(pageType)
  const collectionSlug = isCollection && pageSlug ? pageSlug : 'all'
  const collectionCount = getProductCount(collectionSlug)

  const withArrow =
    link.styles?.style === 'arrow-default' ||
    link.styles?.style === 'arrow-outline'

  // External link
  if (link.url) {
    if (withArrow) {
      return (
        <ArrowButtonLink
          href={link.url}
          target={link.url && !link.url.match('^mailto:') ? '_blank' : '_self'}
          rel="noopener noreferrer"
          tabIndex={tabIndex}
          onClick={onClick}
          outline={link.styles?.style === 'arrow-outline'}
          className={cx(
            {
              btn: link.isButton,
              'w-full': link.styles?.isBlock,
            },
            className
          )}
          aria-label={ariaLabel}
        >
          {children}
        </ArrowButtonLink>
      )
    }

    return (
      <ButtonLink
        href={link.url}
        target={link.url && !link.url.match('^mailto:') ? '_blank' : '_self'}
        rel="noopener noreferrer"
        tabIndex={tabIndex}
        onClick={onClick}
        large={link.styles?.isLarge}
        small={link.styles?.isSmall}
        className={cx(
          {
            btn: link.isButton,
            'w-full': link.styles?.isBlock,
          },
          className
        )}
        aria-label={ariaLabel}
        primary={link.styles?.style === 'primary'}
        secondary={link.styles?.style === 'secondary'}
        tertiary={link.styles?.style === 'tertiary'}
        white={link.styles?.style === 'white'}
        gray={link.styles?.style === 'gray'}
      >
        {children}
      </ButtonLink>
    )
  }

  // Internal Page
  if (!pageType) {
    return null
  }

  const pageUrl = getPageUrl(pageType, pageSlug)

  const getClickHandler = (event: MouseEvent<HTMLAnchorElement>) => {
    if (pageType === PageType.LOGOUT_PAGE) {
      event.preventDefault()
      logoutUser(() => {
        router.push(getPageUrl(PageType.LOGIN_PAGE))
      })
    }

    onClick && onClick()
  }

  const beforeInputHandler = (event: KeyboardEvent<HTMLAnchorElement>) => {
    if (pageType === PageType.LOGOUT_PAGE) {
      event.preventDefault()
      logoutUser(() => {
        router.push(getPageUrl(PageType.LOGIN_PAGE))
      })
    }

    onClick && onClick()
  }

  if (withArrow) {
    return (
      <NextLink
        href={pageUrl}
        scroll={false}
        role="link"
        tabIndex={tabIndex}
        onClick={getClickHandler}
        onBeforeInput={beforeInputHandler}
        className={cx(
          {
            btn: link.isButton,
            'w-full': link.styles?.isBlock,
          },
          getArrowButtonStyles({
            outline: link.styles?.style === 'arrow-outline',
          }),
          className
        )}
        aria-label={ariaLabel}
      >
        {children}
        {
          <ArrowButtonIcon
            isOrderFirst={link.styles?.style !== 'arrow-outline'}
          />
        }
      </NextLink>
    )
  }

  return (
    <NextLink
      href={pageUrl}
      scroll={false}
      role="link"
      tabIndex={tabIndex}
      onClick={getClickHandler}
      onBeforeInput={beforeInputHandler}
      aria-label={ariaLabel}
      className={cx(
        'inline-block',
        {
          btn: link.isButton,
          'w-full': link.styles?.isBlock,
        },
        getButtonStyles({
          large: link.styles?.isLarge,
          small: link.styles?.isSmall,
          primary: link.styles?.style === 'primary',
          secondary: link.styles?.style === 'secondary',
          tertiary: link.styles?.style === 'tertiary',
          white: link.styles?.style === 'white',
          gray: link.styles?.style === 'gray',
        }),
        className
      )}
    >
      {children}

      {showCollectionCount && isCollection && (
        <span
          aria-hidden="true"
          className="inline-block relative ml-2 leading-none align-super text-[.5em]"
        >
          {collectionCount}
        </span>
      )}
    </NextLink>
  )
}

export default Link
