import cx from 'classnames'
import { ButtonHTMLAttributes, forwardRef } from 'react'

export interface ButtonProps {
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  white?: boolean
  gray?: boolean
  small?: boolean
  active?: boolean
  large?: boolean
  wide?: boolean
}

export interface ArrowButtonProps {
  outline?: boolean
}

export const getButtonStyles = ({
  primary,
  secondary,
  tertiary,
  white,
  gray,
  small,
  active,
  large,
  wide,
}: ButtonProps) => {
  const clean = !primary && !secondary && !tertiary && !white && !gray

  return cx(
    {
      'rounded-full text-center transition-colors duration-300': !clean,
      'font-semibold': !clean && !large,
      'py-3 px-7': !small && !clean,
      'py-2 px-4': small && !clean,
      'px-12': wide,
      'text-base': !large && !small && !clean,
      'text-xs': small,
      'text-2xl font-normal': large,
      'bg-white text-dark': white,
      'bg-gray hover:bg-opacity-80 text-white': gray,
      'bg-btn-primary-bg text-btn-primary-text border-btn-primary-border':
        primary,
      'hover:bg-btn-primary-bg-hover hover:text-btn-primary-text-hover hover:border-btn-primary-border-hover':
        primary,
      'bg-btn-secondary-bg border border-btn-secondary-border text-btn-secondary-text':
        secondary,
      'hover:bg-btn-secondary-bg-hover hover:text-btn-secondary-text-hover hover:border-btn-secondary-border-hover':
        secondary,
      'text-btn-secondary-text-hover bg-btn-secondary-bg-hover border-btn-secondary-border-hover':
        active && secondary,
      'bg-btn-tertiary-bg border border-btn-tertiary-border text-btn-tertiary-text':
        tertiary,
      'hover:bg-btn-tertiary-bg-hover hover:text-btn-tertiary-text-hover hover:border-btn-tertiary-border-hover':
        tertiary,
      'text-btn-tertiary-text-hover bg-btn-tertiary-bg-hover border-btn-tertiary-border-hover':
        active && tertiary,
    },
    'disabled:opacity-50'
  )
}

export const getArrowButtonStyles = ({ outline }: ArrowButtonProps) => {
  return cx(
    'inline-flex items-center disabled:opacity-50 transition-colors duration-300',
    {
      'justify-between bg-btn-secondary-bg border border-btn-secondary-border border-opacity-25 hover:border-opacity-60 text-btn-secondary-text py-7 pl-5 pr-4':
        outline,
    }
  )
}

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>((props: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>, ref) => {
  const {
    children,
    className,
    disabled,
    onClick,
    onBeforeInput,
    id,
    style,
    'aria-label': ariaLabel,
  } = props

  return (
    <button
      id={id}
      ref={ref}
      className={cx(getButtonStyles(props), className)}
      onClick={onClick}
      disabled={disabled}
      style={style}
      onBeforeInput={onBeforeInput}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  )
})

Button.displayName = 'Button'

export default Button
