import cx from 'classnames'
import { ButtonHTMLAttributes, useContext, useState } from 'react'

import { SanityProductVariantFragment } from '@data/sanity/queries/types/product'
import { CartContext } from '@lib/cart/context'
import { getPrice } from '@lib/helpers'
import { ShopContext } from '@lib/shop'
import { StringsContext } from '@lib/strings'

import Button, { ButtonProps, getButtonStyles } from './button'

interface AddToCartButtonProps {
  productVariant: SanityProductVariantFragment
  onAddToCartError?: () => void
}

const AddToCartButton = (
  props: AddToCartButtonProps &
    ButtonProps &
    ButtonHTMLAttributes<HTMLButtonElement>
) => {
  const { children, className, productVariant, onAddToCartError } = props

  const { cart, addItemsToCart } = useContext(CartContext)
  const { currency, taxRate } = useContext(ShopContext)
  const strings = useContext(StringsContext)
  const [isAddingToCart, setIsAddingToCart] = useState(false)
  const [isAddToCartError, setIsAddToCartError] = useState(false)

  const handleAddToCart = async () => {
    setIsAddToCartError(false)
    setIsAddingToCart(true)

    const isSuccessful = await addItemsToCart([
      { id: productVariant.id, quantity: 1 },
    ])

    if (!isSuccessful && onAddToCartError) {
      onAddToCartError()
    }

    setIsAddingToCart(false)
  }

  return (
    <span className={cx('inline-flex items-center', className)}>
      <Button
        id="product-add-to-cart-button"
        onClick={handleAddToCart}
        disabled={
          !cart || !productVariant.inStock || isAddingToCart || isAddToCartError
        }
        className={cx(getButtonStyles(props), 'mr-5')}
      >
        {!cart && strings.buttonUnavailable}
        {isAddingToCart && strings.buttonAdding}
        {!productVariant.inStock && strings.productOutOfStock}
        {cart &&
          productVariant.inStock &&
          !isAddingToCart &&
          !isAddToCartError &&
          children}
      </Button>

      <span
        className={cx({
          'text-sm': props.small,
          'text-base': !props.small,
        })}
      >
        {currency}
        {getPrice(productVariant.price, taxRate)}
      </span>
    </span>
  )
}

export default AddToCartButton
