import { AnchorHTMLAttributes } from 'react'
import cx from 'classnames'

import { ButtonProps, getButtonStyles } from './button'

const ButtonLink = (
  props: ButtonProps & AnchorHTMLAttributes<HTMLAnchorElement>
) => {
  const { children, className, href, target, rel, tabIndex, style, onClick } =
    props

  return (
    <a
      href={href}
      target={target}
      tabIndex={tabIndex}
      rel={rel}
      style={style}
      onClick={onClick}
      className={cx(getButtonStyles(props), className)}
    >
      {children}
    </a>
  )
}

export default ButtonLink
