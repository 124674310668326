import {
  AttributeInput,
  CartAttributesUpdateMutation,
  CartAttributesUpdateMutationVariables,
  CartCreateMutation,
  CartCreateMutationVariables,
  CartFragmentFragment,
  CartInput,
  CartLineInput,
  CartLineUpdateInput,
  CartLinesAddMutation,
  CartLinesAddMutationVariables,
  CartLinesRemoveMutation,
  CartLinesRemoveMutationVariables,
  CartLinesUpdateMutation,
  CartLinesUpdateMutationVariables,
  CartNoteUpdateMutation,
  CartNoteUpdateMutationVariables,
} from '@data/shopify/storefront/types'
import {
  CART_ATTRIBUTES_UPDATE,
  CART_CREATE,
  CART_LINES_ADD,
  CART_LINES_REMOVE,
  CART_LINES_UPDATE,
  CART_NOTE_UPDATE,
} from '@data/shopify/storefront/mutations/cart'
import { ShopifyClient } from './client'

export interface CartResponse {
  cart?: CartFragmentFragment
  error?: string
}

export type CartFragmentFragmentLine =
  CartFragmentFragment['lines']['edges'][0]['node']

/**
 * Creates a new Shopify cart.
 */
export const createShopifyCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartInput: CartInput
): Promise<CartResponse> => {
  try {
    const cartCreateResult = await shopifyStorefrontClient.mutate<
      CartCreateMutation,
      CartCreateMutationVariables
    >({
      mutation: CART_CREATE,
      variables: {
        cartInput,
      },
    })

    const cart = cartCreateResult.data?.cartCreate?.cart

    if (!cart) {
      const firstErrorMessage =
        cartCreateResult.data?.cartCreate?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    const cartFragment = cart as CartFragmentFragment

    return {
      cart: cartFragment,
    }
  } catch (error) {
    return {
      error: `${error}`,
    }
  }
}

/**
 * Updates attributes on Shopify cart.
 */
export const updateShopifyCartAttrbites = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  attributes: AttributeInput[]
): Promise<CartResponse> => {
  try {
    const cartAttributesUpdateResult = await shopifyStorefrontClient.mutate<
      CartAttributesUpdateMutation,
      CartAttributesUpdateMutationVariables
    >({
      mutation: CART_ATTRIBUTES_UPDATE,
      variables: {
        cartId,
        attributes,
      },
    })

    const cart = cartAttributesUpdateResult.data?.cartAttributesUpdate?.cart

    if (!cart) {
      const firstErrorMessage =
        cartAttributesUpdateResult.data?.cartAttributesUpdate?.userErrors?.[0]
          ?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    const cartFragment = cart as CartFragmentFragment

    return {
      cart: cartFragment,
    }
  } catch (error) {
    return {
      error: `${error}`,
    }
  }
}

/**
 * Updates note on Shopify cart.
 */
export const updateShopifyCartNote = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  note: string
): Promise<CartResponse> => {
  try {
    const cartNoteUpdateResult = await shopifyStorefrontClient.mutate<
      CartNoteUpdateMutation,
      CartNoteUpdateMutationVariables
    >({
      mutation: CART_NOTE_UPDATE,
      variables: {
        cartId,
        note,
      },
    })

    const cart = cartNoteUpdateResult.data?.cartNoteUpdate?.cart

    if (!cart) {
      const firstErrorMessage =
        cartNoteUpdateResult.data?.cartNoteUpdate?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    const cartFragment = cart as CartFragmentFragment

    return {
      cart: cartFragment,
    }
  } catch (error) {
    return {
      error: `${error}`,
    }
  }
}

/**
 * Adds new cart line items.
 */
export const addLineItemsToShopifyCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  lines: CartLineInput[]
): Promise<CartResponse> => {
  try {
    const cartLinesAddResult = await shopifyStorefrontClient.mutate<
      CartLinesAddMutation,
      CartLinesAddMutationVariables
    >({
      mutation: CART_LINES_ADD,
      variables: {
        cartId,
        lines,
      },
    })

    const cart = cartLinesAddResult.data?.cartLinesAdd?.cart

    if (!cart) {
      const firstErrorMessage =
        cartLinesAddResult.data?.cartLinesAdd?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    const cartFragment = cart as CartFragmentFragment

    return {
      cart: cartFragment,
    }
  } catch (error) {
    return {
      error: `${error}`,
    }
  }
}

/**
 * Updates cart line items.
 */
export const updateLineItemsInShopifyCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  lines: CartLineUpdateInput[]
): Promise<CartResponse> => {
  try {
    const cartLinesUpdateResult = await shopifyStorefrontClient.mutate<
      CartLinesUpdateMutation,
      CartLinesUpdateMutationVariables
    >({
      mutation: CART_LINES_UPDATE,
      variables: {
        cartId,
        lines,
      },
    })

    const cart = cartLinesUpdateResult.data?.cartLinesUpdate?.cart

    if (!cart) {
      const firstErrorMessage =
        cartLinesUpdateResult.data?.cartLinesUpdate?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    const cartFragment = cart as CartFragmentFragment

    return {
      cart: cartFragment,
    }
  } catch (error) {
    return {
      error: `${error}`,
    }
  }
}

/**
 * Removes cart line items.
 */
export const removeLineItemsFromShopifyCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  lineIds: string[]
): Promise<CartResponse> => {
  try {
    const cartLinesRemoveResult = await shopifyStorefrontClient.mutate<
      CartLinesRemoveMutation,
      CartLinesRemoveMutationVariables
    >({
      mutation: CART_LINES_REMOVE,
      variables: {
        cartId,
        lineIds,
      },
    })

    const cart = cartLinesRemoveResult.data?.cartLinesRemove?.cart

    if (!cart) {
      const firstErrorMessage =
        cartLinesRemoveResult.data?.cartLinesRemove?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    const cartFragment = cart as CartFragmentFragment

    return {
      cart: cartFragment,
    }
  } catch (error) {
    return {
      error: `${error}`,
    }
  }
}
