import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
} from 'react'

import { CartLineInput } from '@data/shopify/storefront/types'
import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import { getShopifyGlobalId } from '@lib/shopify/client'
import {
  addLineItemsToShopifyCart,
  removeLineItemsFromShopifyCart,
  updateLineItemsInShopifyCart,
  updateShopifyCartAttrbites,
  updateShopifyCartNote,
} from '@lib/shopify/graphql/cart'
import { getTaxCartAttributes } from '@lib/shopify/cart'
import { getSanityClient } from '@lib/sanity/client'
import { getProductVariants } from '@lib/sanity/product'
import { DiscountContext } from '@lib/discount'
import { triggerAddToCartFacebookEvent } from '@lib/facebook'
import { Locale } from '@lib/language'
import { useGetPartnerAdsAttributes } from '@lib/partner-ads'
import { ShopContext } from '@lib/shop'
import { StringsContext } from '@lib/strings'
import { CartContext } from './context'
import { Cart, CartFormValues, CartTotals, CartVariantLineItem } from './types'
import { updateCartDiscount, validateCart } from './helpers'

/**
 * Returns cart item count.
 */
export const useCartItemCount = () => {
  const { cart } = useContext(CartContext)

  return useMemo(
    () =>
      cart?.lineItems?.reduce((total, { quantity }) => total + quantity, 0) ??
      0,
    [cart?.lineItems]
  )
}

/**
 * Returns cart line item and discount totals.
 */
export const useCartTotals = (): CartTotals => {
  const { cart } = useContext(CartContext)
  const { cartDiscountItems } = useContext(DiscountContext)

  return useMemo(() => {
    const productDiscountTotal =
      cart?.lineItems?.reduce(
        (sum, { comparePrice, quantity, price }) =>
          sum + (comparePrice ? comparePrice - price : 0) * quantity,
        0
      ) ?? 0
    const totalDiscount =
      cartDiscountItems?.reduce(
        (sum, { amount, quantity }) => sum + amount * quantity,
        0
      ) ?? 0
    const subTotal = cart?.subTotal ?? 0
    const total = cart?.total ?? 0

    return {
      productDiscountTotal,
      totalDiscount,
      subTotal: subTotal + productDiscountTotal,
      total,
    }
  }, [cart?.lineItems, cart?.subTotal, cart?.total, cartDiscountItems])
}

export const useAddItemsToCart = (
  site: SanitySiteFragment,
  cart: Cart,
  locale: Locale,
  setIsCartProductAdding: Dispatch<SetStateAction<boolean>>,
  setIsCartUpdating: Dispatch<SetStateAction<boolean>>,
  saveCart: (locale: Locale, cart?: Cart) => void,
  toggleCart: (newState: boolean) => void
) => {
  const { shopifyStorefrontClient } = useContext(ShopContext)

  const getPartnerAdsAttributes = useGetPartnerAdsAttributes()

  return useCallback(
    async (variantLineItems: CartVariantLineItem[]): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartProductAdding(true)
      setIsCartUpdating(true)

      // Get variant details from Sanity
      const sanityClient = getSanityClient()
      const variantIds = variantLineItems.map(({ id }) => id)
      const productVariants = await getProductVariants(
        sanityClient,
        locale,
        variantIds
      )

      const lines: CartLineInput[] = variantLineItems.map(
        ({ id, quantity }) => {
          const variant = productVariants.find((variant) => id === variant.id)
          const partnerAdsAttributes = getPartnerAdsAttributes(true)

          return {
            merchandiseId: getShopifyGlobalId('ProductVariant', id),
            sellingPlanId: variant?.sellingPlanId
              ? getShopifyGlobalId('SellingPlan', variant?.sellingPlanId)
              : null,
            quantity,
            attributes: [...partnerAdsAttributes],
          }
        }
      )
      const cartResponse = await addLineItemsToShopifyCart(
        shopifyStorefrontClient,
        cart.id,
        lines
      )

      if (cartResponse.error) {
        setIsCartProductAdding(false)
        setIsCartUpdating(false)
        return false
      }

      if (site.settings.facebookEvents) {
        productVariants.forEach(async (variant) => {
          await triggerAddToCartFacebookEvent(locale, variant)
        })
      }

      // Update cart discount codes
      const newCart = await updateCartDiscount(locale, cart.id)

      if (!newCart) {
        setIsCartProductAdding(false)
        setIsCartUpdating(false)
        return false
      }

      saveCart(locale, newCart)

      setIsCartProductAdding(false)
      setIsCartUpdating(false)
      toggleCart(false)

      return !!newCart
    },
    [
      cart.id,
      getPartnerAdsAttributes,
      locale,
      saveCart,
      setIsCartProductAdding,
      setIsCartUpdating,
      shopifyStorefrontClient,
      site.settings.facebookEvents,
      toggleCart,
    ]
  )
}

export const useUpdateCartItem = (
  cart: Cart,
  locale: Locale,
  setIsCartUpdating: Dispatch<SetStateAction<boolean>>,
  saveCart: (locale: Locale, cart?: Cart) => void
) => {
  const { shopifyStorefrontClient } = useContext(ShopContext)

  return useCallback(
    async (id: string, quantity: number): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartUpdating(true)

      // Update cart line items
      const cartResponse = await updateLineItemsInShopifyCart(
        shopifyStorefrontClient,
        cart.id,
        [{ id, quantity }]
      )

      if (cartResponse.error) {
        return false
      }

      // Update cart discount codes
      const newCart = await updateCartDiscount(locale, cart.id)

      if (!newCart) {
        return false
      }

      saveCart(locale, newCart)

      setIsCartUpdating(false)

      return !!newCart
    },
    [cart.id, locale, saveCart, setIsCartUpdating, shopifyStorefrontClient]
  )
}

export const useRemoveItemFromCart = (
  cart: Cart,
  locale: Locale,
  setIsCartUpdating: Dispatch<SetStateAction<boolean>>,
  saveCart: (locale: Locale, cart?: Cart) => void
) => {
  const { shopifyStorefrontClient } = useContext(ShopContext)

  return useCallback(
    async (id: string): Promise<boolean> => {
      if (!cart.id) {
        return false
      }

      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartUpdating(true)

      // Remove line item from Shopify cart
      const cartResponse = await removeLineItemsFromShopifyCart(
        shopifyStorefrontClient,
        cart.id,
        [id]
      )

      if (cartResponse.error) {
        return false
      }

      // Update cart discount codes
      const newCart = await updateCartDiscount(locale, cart.id)

      if (!newCart) {
        return false
      }

      saveCart(locale, newCart)

      setIsCartUpdating(false)

      return !!newCart
    },
    [cart.id, locale, saveCart, setIsCartUpdating, shopifyStorefrontClient]
  )
}

export const useSubmitCart = (
  cart: Cart,
  setIsCartSubmitting: Dispatch<SetStateAction<boolean>>
) => {
  const { countryCode, shopifyStorefrontClient } = useContext(ShopContext)
  const strings = useContext(StringsContext)

  const getPartnerAdsAttributes = useGetPartnerAdsAttributes()

  return useCallback(
    async (values: CartFormValues) => {
      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setIsCartSubmitting(true)

      // Validate cart form
      const { errors, vatIdCountryCode } = await validateCart(strings, values)

      if (cart?.id && Object.entries(errors).length === 0) {
        // Update cart attributes
        const taxCartAttributes = getTaxCartAttributes(
          values,
          vatIdCountryCode !== countryCode
        )
        const partnerAdsAttributes = getPartnerAdsAttributes(false)

        await updateShopifyCartAttrbites(shopifyStorefrontClient, cart.id, [
          ...taxCartAttributes,
          ...partnerAdsAttributes,
        ])

        // Update cart note
        await updateShopifyCartNote(
          shopifyStorefrontClient,
          cart.id,
          values.comment ?? ''
        )
      }

      setIsCartSubmitting(false)

      return { errors }
    },
    [
      cart.id,
      countryCode,
      getPartnerAdsAttributes,
      setIsCartSubmitting,
      shopifyStorefrontClient,
      strings,
    ]
  )
}
