import { createContext, useEffect, useState, ReactNode } from 'react'

import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import { SanityProductCatalogueQuery } from '@data/sanity/queries/types/product'
import { getShopifyShopAndCart, ShopifyShop } from './shopify/graphql/shop'
import {
  getShopifyStorefrontClient,
  ShopifyClient,
} from './shopify/graphql/client'
import { getShopifyDomain } from './shopify/client'
import { Locale } from './language'
import { CartContextProvider } from './cart/context'
import { getCartIdStorageKey } from './cart/helpers'
import { DiscountContextProvider } from './discount'

export interface LocalizedShop {
  locale: Locale
  shop: ShopifyShop | null
}

interface ShopContextProps {
  taxRate: number
  currency?: string
  currencyCode?: string
  countryCode?: string
  shopifyDomain: string
  shopifyPrimaryDomain: string
  productCatalogue: SanityProductCatalogueQuery
  shopifyStorefrontClient?: ShopifyClient
}

export const ShopContext = createContext<ShopContextProps>({
  taxRate: 0,
  shopifyDomain: '',
  shopifyPrimaryDomain: '',
  productCatalogue: [],
})

interface ShopContextProviderProps {
  locale: Locale
  site: SanitySiteFragment
  productCatalogue: SanityProductCatalogueQuery
  children: ReactNode
}

export const ShopContextProvider = ({
  locale,
  site,
  productCatalogue,
  children,
}: ShopContextProviderProps) => {
  const shopifyDomain = getShopifyDomain(locale)

  const [localeInitialised, setLocaleInitialised] = useState<Locale>()
  const [currency, setCurrency] = useState<string>()
  const [currencyCode, setCurrencyCode] = useState<string>()
  const [countryCode, setCountryCode] = useState<string>()
  const [shopifyPrimaryDomain, setShopifyPrimaryDomain] =
    useState<string>(shopifyDomain)
  const [localizedShop, setLocalizedShop] = useState<LocalizedShop>({
    locale,
    shop: null,
  })

  const shopifyStorefrontClient = getShopifyStorefrontClient(locale)

  // Get Shopify shop and cart information (update when switching language)
  useEffect(() => {
    if (localeInitialised === locale) {
      return
    }

    setLocalizedShop({
      locale,
      shop: null,
    })

    const loadShop = async () => {
      if (!shopifyStorefrontClient) {
        throw new Error('Shopify Storefront API client missing')
      }

      setLocaleInitialised(locale)

      const cartIdStorageKey = getCartIdStorageKey(locale)
      const cartId = localStorage.getItem(cartIdStorageKey)
      const shopifyShop = await getShopifyShopAndCart(
        shopifyStorefrontClient,
        cartId
      )
      setLocalizedShop({
        locale,
        shop: shopifyShop ?? null,
      })
    }

    loadShop()
  }, [localeInitialised, locale, shopifyStorefrontClient])

  useEffect(() => {
    if (!localizedShop.shop) {
      return
    }

    // Remove HTML tags and value placeholder "{{amount}}" from currency format string
    setCurrency(
      localizedShop.shop?.shop?.moneyFormat
        ?.replace(/<\/?[^>]+(>|$)/g, '')
        ?.replace(/\s*[{]+.*?[}]+\s*/g, '')
    )
    setCurrencyCode(localizedShop.shop?.shop?.paymentSettings?.currencyCode)
    setCountryCode(localizedShop.shop?.shop?.paymentSettings?.countryCode)
    setShopifyPrimaryDomain(
      localizedShop.shop?.shop?.primaryDomain?.host ?? shopifyDomain
    )
  }, [localizedShop.shop, shopifyDomain])

  return (
    <ShopContext.Provider
      value={{
        taxRate: site.cart.taxRate ?? 0,
        currency,
        currencyCode,
        countryCode,
        shopifyDomain,
        shopifyPrimaryDomain,
        productCatalogue,
        shopifyStorefrontClient,
      }}
    >
      <CartContextProvider
        localizedShop={localizedShop}
        site={site}
        locale={locale}
      >
        <DiscountContextProvider site={site}>
          {children}
        </DiscountContextProvider>
      </CartContextProvider>
    </ShopContext.Provider>
  )
}
