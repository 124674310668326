import { useContext, MouseEvent, Dispatch, SetStateAction } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { useForm } from 'react-hook-form'

import { SanityContentFragment } from '@data/sanity/queries/types/content'
import { CartContext } from '@lib/cart/context'
import { getCheckoutUrlStorageKey } from '@lib/checkout'
import { DiscountContext } from '@lib/discount'
import { triggerInitiateCheckoutFacebookEvent } from '@lib/facebook'
import { ErrorMessages } from '@lib/helpers'
import { LanguageContext, Locale } from '@lib/language'
import { serializers } from '@lib/serializers'
import { ShopContext } from '@lib/shop'
import { StringsContext } from '@lib/strings'
import { SiteContext } from '@lib/site'

import Button from '@components/buttons/button'
import Checkbox from '@components/checkbox'

/**
 * Adds Google Analytics linker param to URL.
 */
const addLinkerParamToUrl = async (url: string) =>
  new Promise<string>((resolve) => {
    if (!window.ga) {
      resolve(url)
      return
    }

    window.ga(() => {
      const linkerParam = window.ga?.getAll()?.[0]?.get('linkerParam')
      const searchParams = new URLSearchParams(linkerParam)

      const newUrl = new URL(url)
      searchParams.forEach((value, key) => newUrl.searchParams.set(key, value))
      resolve(newUrl.toString())
    })
  })

/**
 * Adds locale param to URL.
 */
const addLocaleParamToUrl = (url: string, locale: Locale) => {
  const searchParams = new URLSearchParams(`locale=${locale}`)
  const newUrl = new URL(url)
  searchParams.forEach((value, key) => newUrl.searchParams.set(key, value))

  return newUrl.toString()
}

/**
 * Formats checkout URL by replacing host and adding linker param.
 */
const formatCheckoutUrl = async (
  checkoutUrl: string,
  locale: Locale,
  storeUrl?: string
) => {
  let formattedCheckoutUrl = `${checkoutUrl}`

  // Update checkout URL to use custom domain name
  if (storeUrl) {
    formattedCheckoutUrl = formattedCheckoutUrl.replace(
      /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/g,
      storeUrl
    )
  }

  formattedCheckoutUrl = addLocaleParamToUrl(formattedCheckoutUrl, locale)
  formattedCheckoutUrl = await addLinkerParamToUrl(formattedCheckoutUrl)

  return formattedCheckoutUrl
}

interface CartSubmitProps {
  vatId: string
  comment: string
  setErrorMessages: Dispatch<SetStateAction<ErrorMessages>>
  storeUrl?: string
  onClick?: () => void
  terms?: Array<SanityContentFragment>
}

const CartSubmit = ({
  vatId,
  comment,
  setErrorMessages,
  storeUrl,
  onClick,
  terms,
}: CartSubmitProps) => {
  const { register, watch } = useForm()
  const { currencyCode } = useContext(ShopContext)
  const { cart, isCartUpdating, isCartSubmitting, submitCart } =
    useContext(CartContext)
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)
  const { cartDiscountItems } = useContext(DiscountContext)
  const { settings } = useContext(SiteContext)

  const acceptTermsRegister = register('acceptTerms')
  const hasAgreed = watch('acceptTerms')

  // Update checkout URL to use custom domain name
  const checkoutUrl = cart?.webUrl

  const goToCheckout = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    const { errors } = await submitCart({ vatId, comment })

    setErrorMessages((messages) => ({ ...messages, vatId: errors.vatId }))

    if (checkoutUrl && Object.entries(errors).length === 0) {
      onClick && onClick()

      const formattedCheckoutUrl = await formatCheckoutUrl(
        checkoutUrl,
        locale,
        storeUrl
      )

      setTimeout(async () => {
        // Save checkout URL
        localStorage.setItem(
          getCheckoutUrlStorageKey(locale),
          formattedCheckoutUrl
        )

        if (settings?.facebookEvents) {
          await triggerInitiateCheckoutFacebookEvent(
            locale,
            cart,
            cartDiscountItems ?? [],
            currencyCode
          )
        }

        // Redirect to checkout
        window.location.href = formattedCheckoutUrl
      }, 0)
    }
  }

  return (
    <>
      {terms && (
        <Checkbox
          id={`cart-acceptTerms`}
          formRegister={acceptTermsRegister}
          className="mb-4 sm:px-4 mx-auto"
        >
          <BlockContent
            renderContainerOnSingleChild
            className="rc"
            blocks={terms}
            serializers={serializers}
          />
        </Checkbox>
      )}
      <Button
        primary
        large
        className="w-full"
        disabled={isCartUpdating || isCartSubmitting || (terms && !hasAgreed)}
        onClick={goToCheckout}
      >
        {isCartUpdating && strings.buttonUpdating}
        {isCartSubmitting && strings.buttonSubmitting}
        {!isCartUpdating && !isCartSubmitting && strings.cartSubmit}
      </Button>
    </>
  )
}

export default CartSubmit
