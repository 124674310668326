import { ButtonHTMLAttributes, ReactNode } from 'react'
import cx from 'classnames'

import Icon from '@components/icon'

export interface PlayButtonProps {
  children?: ReactNode
  white?: boolean
}

const PlayButton = ({
  children,
  className,
  onClick,
  white,
}: PlayButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const withText = !!children

  return (
    <button
      onClick={onClick}
      className={cx(
        {
          'p-0': !withText,
          'inline-flex items-center justify-between bg-btn-secondary-bg border border-btn-secondary-border border-opacity-25 hover:border-opacity-60 text-btn-secondary-text py-7 pl-5 pr-4':
            withText,
        },
        'group transition-colors duration-400',
        className
      )}
    >
      {children}
      <PlayIcon
        small={withText}
        large={!withText}
        whiteBackground={white}
        className={cx({ 'ml-5': withText })}
      />
    </button>
  )
}

interface PlayIconProps {
  small?: boolean
  large?: boolean
  whiteBackground?: boolean
  className?: string
}

const PlayIcon = ({
  small,
  large,
  whiteBackground,
  className,
}: PlayIconProps) => {
  return (
    <span
      className={cx(
        'inline-flex items-center justify-center rounded-full',
        {
          'bg-btn-primary-bg group-hover:bg-btn-primary-bg-hover transition duration-400':
            !whiteBackground,
          'bg-white': whiteBackground,
          'w-[35px] h-[35px]': small,
          'w-[80px] h-[80px]': large,
        },
        className
      )}
    >
      <Icon
        id="play-button-icon"
        name="Play"
        viewBox="0 0 15 18"
        className={cx('block w-[20%] ml-[1px]', {
          '!text-dark': whiteBackground,
          '!text-btn-primary-text': !whiteBackground,
        })}
      />
    </span>
  )
}

export default PlayButton
